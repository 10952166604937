import * as React from 'react'
import { connect } from 'react-redux'
import { compose, path } from 'ramda'
import { routerActions } from 'react-router-redux'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import CreateEntityButton from 'components/CreateEntityButton'
import CovaultDropdown from 'components/CovaultDropdown'
import CovaultDatePicker from 'components/CovaultDatePicker'
import CovaultFilePicker from 'components/CovaultFilePicker'
import CovaultTextField from 'components/CovaultTextField'
import { SalesProgramsQueryDocument, CreateSalesProgramMutationDocument } from '../../graphql'
import { graphql } from 'react-apollo'
import { startManagedUpload } from 'utils/upload'
import { auth } from 'components/CovaultAuth'
import withSnackbar from 'components/WithSnackbar'
import { generatePath } from 'react-router'
import { SALES_PROGRAM_PATH } from 'constants/paths'

const halfWidth = {
  width: '50%',
}

export class CreateSalesProgram extends React.Component {
  static propTypes = {
    defaultValues: PropTypes.shape({
      salesProgram: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        status: PropTypes.string,
      }),
      productLineId: PropTypes.number,
    }),
    replace: PropTypes.func,
    SalesProgramQuery: PropTypes.object.isRequired,
    snackbarContext: PropTypes.object.isRequired,
    t: PropTypes.func,
  }

  state = {
    file: void 0,
    progress: void 0,
    loading: void 0,
  }

  uploadFile = async assumeRole => {
    if (!this.state.file) {
      this.props.snackbarContext.successMessage('Sales Program Created', '')
      return this.setState({ loading: false })
    }

    const managedUpload = startManagedUpload(this.state.file, assumeRole)
    managedUpload.on('httpUploadProgress', ({ loaded, total }) =>
      this.setState({ progress: total > 0 ? loaded / total : 0 })
    )
    await managedUpload.promise().then(
      () => {
        this.props.snackbarContext.successMessage('Sales Program Document Uploaded', '')
      },
      error => {
        this.props.snackbarContext.errorMessage('Uploading Sales Program Document Failed', '')
      }
    )

    this.setState({ loading: void 0, progress: void 0 })
  }

  render() {
    const {
      props: {
        defaultValues,
        replace,
        SalesProgramQuery: { brand },
        t,
      },
      state: { loading, progress },
    } = this

    // We can't offer this action if there are no ProductLines defined for the brand.
    if (!path(['productLines'], brand)) {
      return null
    }

    return (
      <CreateEntityButton
        mutation={CreateSalesProgramMutationDocument}
        refetchQueries={() => [
          {
            query: SalesProgramsQueryDocument,
            variables: { brandId: brand.id },
          },
        ]}
        onCompleted={async ({
          createSalesProgram: {
            assumeRole,
            salesProgram: { id },
          },
        }) => {
          await this.uploadFile(assumeRole)
          replace(generatePath(SALES_PROGRAM_PATH, { id, brandId: brand.id }))
        }}
        buttonText={t('Create Sales Program')}
        shortButtonText={t('New')}
        initialVariables={[['salesProgram.status', 'DRAFT']]}
        progress={progress}
        loading={loading}
        submitHook={() => this.setState({ loading: true })}
        errorHook={() => this.setState({ loading: false })}
      >
        {setValueFactory => (
          <React.Fragment>
            <CovaultTextField
              id="code"
              setValue={setValueFactory('salesProgram.code')}
              defaultValue={path(['salesProgram', 'code'], defaultValues) || ''}
              label={t('Sales Program Code')}
            />
            <CovaultTextField
              id="name"
              setValue={setValueFactory('salesProgram.name')}
              defaultValue={path(['salesProgram', 'name'], defaultValues) || ''}
              label={t('Name')}
            />
            <CovaultDropdown
              testid="productLineSelect"
              setValue={setValueFactory('productLineId')}
              itemNames={brand.productLines.map(({ name }) => name)}
              itemValues={brand.productLines.map(({ id }) => Number(id))}
              defaultValue={path(['productLineId'], defaultValues)}
              label={t('Product Line')}
            />
            <CovaultFilePicker
              accept=".pdf"
              label={t('Description Document')}
              setValue={(hasFile, file) => {
                if (!hasFile) {
                  setValueFactory('salesProgram.documentFilename')(null)
                  this.setState({ file: void 0 })
                } else {
                  this.setState({ file })
                  setValueFactory('salesProgram.documentFilename')(file.name)
                }
              }}
            />
            <CovaultDatePicker
              testid="startDate"
              setValue={setValueFactory('salesProgram.startDate')}
              defaultValue={path(['salesProgram', 'startDate'], defaultValues)}
              label={t('Start Date') || ''}
              autoOk
              disablePast
              style={halfWidth}
            />
            <CovaultDatePicker
              testid="endDate"
              setValue={setValueFactory('salesProgram.endDate')}
              defaultValue={path(['salesProgram', 'endDate'], defaultValues)}
              label={t('End Date') || ''}
              autoOk
              disablePast
              style={halfWidth}
            />
          </React.Fragment>
        )}
      </CreateEntityButton>
    )
  }
}

export default compose(
  withSnackbar,
  translate(),
  auth({
    requiredParent: ['brand', 'covault'],
    requiredPermission: 'sales_program:manage',
  }),
  connect(
    null,
    { replace: routerActions.replace }
  ),
  graphql(SalesProgramsQueryDocument, {
    name: 'SalesProgramQuery',
    options: props => ({
      variables: {
        brandId: props.brandId,
      },
    }),
  })
)(CreateSalesProgram)
