import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import CreateAd from 'components/EntityButtons/CreateAd'
import { Dialog, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { css } from 'emotion'

const wrapperStyles = css`
  padding-bottom: 24px;
`

export default function SalesProgramSelectDialog({ onClose, salesPrograms, open }) {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Select Sales Program</DialogTitle>
      <div className={wrapperStyles}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Code</Trans>
              </TableCell>
              <TableCell>
                <Trans>Product Line</Trans>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {salesPrograms
              .filter(salesProgram => Date.parse(salesProgram.endDate) > new Date())
              .map(salesProgram => {
                const {
                  id,
                  code,
                  name,
                  productLine: { name: productLineName },
                  campaignTemplates,
                } = salesProgram
                const campaignTemplate = campaignTemplates[0]

                return (
                  <TableRow key={id}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{code}</TableCell>
                    <TableCell>{productLineName}</TableCell>
                    <TableCell>
                      <CreateAd salesProgram={salesProgram} buttonText={'Select'} campaignTemplate={campaignTemplate} />
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </div>
    </Dialog>
  )
}

SalesProgramSelectDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  salesPrograms: PropTypes.array.isRequired,
}
